@import '_normilize';
// $maintheme: #341f5e;

body {
	font-family: 'Roboto Mono', monospace;
	color: rgba(0, 0, 0, 1);
}

.btn {
	color: white;
	padding: 15px 20px;
	border-radius: 10px;
	text-decoration: none;
	border: none;
	font-weight: 700;
	transition: all 200ms ease;

	&:hover {
		transform: scale(1.05);
	}
}

.btn-black {
	color: white;
	background-color: black;
	padding: 15px 25px;
	margin-top: 30px;
	border-radius: 10px;
	border: none;
	font-size: 20px;
	font-weight: 700;
	transition: all 200ms ease;

	&:hover {
		transform: scale(1.05);
	}

	&:active {
		background-color: white;
		color: black;
		transform: translateY(4px);
	}
}

.btn-post {
	background-color: #751692;
}

.btn-cancel {
	background-color: black;
}

.btn-blue {
	background-color: #3498db;
}


.home {
	// background-color: #c7b9ff;
	padding-top: 100px;

	.welcome {
		display: grid;
		justify-content: center;
		align-items: center;
		grid-template-columns: 1fr 1fr;
		gap: 130px;
		max-width: 1300px;
		margin: 0 auto;

		img {
			display: block;
			max-width: 100%;
		}

		h1 {
			font-size: 64px;
			line-height: 1;
			letter-spacing: -0.02em;
			margin-bottom: 32px;

			div {
				font-size: 22px;
				margin-bottom: 40px;
			}
		}

		p {
			margin-bottom: 20px;
		}
	}
}

.sign-page {
	display: block; 
	position: fixed;
	z-index: 1; 
	left: 0;
	top: 0;
	width: 100%;
	height: 100%; 
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.4); 

	.sign-page-content {
		padding-bottom: 60px;
		background-color: #fefefe;
		margin: 10% auto;
		border: 3px solid black;
		border-radius: 8px;
		max-width: 600px; 
		position: relative;
		animation-name: animatetop;
		animation-duration: 400ms;

		.close {
			position: absolute;
			top: 10px;
			right: 20px;
			color: #aaa;
			font-size: 35px;
			font-weight: bold;
			transition: all 200ms ease;

			&:hover, &:focus {
				color: #c7b9ff;
				text-decoration: none;
				cursor: pointer;
				transform: scale(1.2);
			}
		}

		.status-text {
			display: flex;
			justify-content: center;
			padding-top: 50px;
		}
	}

	#sign-form {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 70px 110px;
		padding-bottom: 0px;
	
		input {
			outline: none;
			height: 50px;
			padding-left: 30px;
			border: 2px solid black;
			border-radius: 5px;
	
			&:hover, &:focus {
				border: 3px solid #c7b9ff;		
			}
		}
	
		.signup-q {
			align-self: flex-end;
		}
	}
}


header {

	div.upload-files {
		display: grid;
		grid-template-columns: 200px 1fr;
		grid-template-rows: auto;
		margin: 40px 50px;

		.file-list {
			margin-top: 20px;
			margin-left: 110px;
			grid-column: 2/3;
			grid-row: 1/3;

			li {
				list-style: none;
				padding-bottom: 15px;
			}
		}

		.btn-merge {
			align-self: end;
			background-color: rgb(115, 197, 115);
			height: 60px;
			color: black;

			&:hover {
				background-color: #40953b;
			}

			&:active {
				background-color: rgb(115, 197, 115);
				color: white;
			}
		}

		label {
			display: flex;
			justify-content: center;
			align-items: center;
			column-gap: 10px;
			min-height: 60px;
			background-color: rgb(226, 226, 226);
			color: black;
			padding: 15px 20px;
			border-radius: 10px;
			text-decoration: none;
			// border: 2px solid black;
			font-weight: 700;
			cursor: pointer;
			transition: all 200ms ease;

			&:hover {
				transform: scale(1.05);
			}
		}

		input#upload-file {
			visibility: hidden;
		}
	}

}

.flex-nav {
	font-size: 1.2em;
	font-weight: 700;
	padding: 30px 50px;

	ul {
		list-style: none;
		display: flex;

		align-items: center;

		li {
			min-height: 45px;
			text-align: center;
			flex: 0 1 150px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
		}

		li.nav-logo {
			margin-right: auto;
			// margin-left: 0px;

			img {
				width: 40px;
				transition: all 200ms ease;

				&:hover {
					cursor: pointer;
					transform: scale(1.1);
				}
			}
		}
		
		.nav-sign {
			 button {
				margin-top: 0;
			 }
		}

		.ratings {
			text-decoration: none;
			cursor: pointer;
			color: inherit;
			position: relative;
		}

		.menu-options {
			position: absolute;
			top: 40px;
			z-index: 999;
			border: 3px solid black;
			background-color: white;
			padding: 20px 40px;
			display: grid;
			row-gap: 20px;
			white-space: nowrap;
		}
	}
}

.element-underline {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		bottom: -2px;
		left: 0;
		width: 100%;
		height: 3px;
		background-color: #6495ed;
		opacity: 0;
		transition: opacity 300ms, transform 300ms;
	}

	&:hover::after {
		opacity: 1;
		transform: translate3d(0, 0.2em, 0);
	}
}

.element-underline-width {
	text-decoration: none;
	cursor: pointer;
	color: inherit;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		bottom: -5px;
		left: 0;
		width: 0%;
		height: 3px;
		background-color: #6495ed;
		opacity: 0;
		transition: opacity 300ms, transform 300ms, width 300ms;
	}

	&:hover::after {
		width: 100%;
		opacity: 1;
	}
}

.modal {
	display: block; 
	position: fixed;
	z-index: 1; 
	left: 0;
	top: 0;
	width: 100%;
	height: 100%; 
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.4); 

	.modal-content {
		background-color: #fefefe;
		margin: 10% auto;
		border: 3px solid black;
		border-radius: 8px;
		max-width: 600px; 
		position: relative;
		animation-name: animatetop;
		animation-duration: 400ms;

		.close {
			position: absolute;
			top: 10px;
			right: 20px;
			color: #aaa;
			font-size: 35px;
			font-weight: bold;
			transition: all 200ms ease;

			&:hover, &:focus {
				color: #c7b9ff;
				text-decoration: none;
				cursor: pointer;
				transform: scale(1.2);
			}
		}

		.status-text {
			display: flex;
			justify-content: center;
			padding: 60px 0px;
		}
	}
}

.table-type {
	font-size: 18px;
	padding-top: 30px;

	.choose-table-type {
		padding-left: 20px;
		display: flex;
		flex-wrap: wrap;
		row-gap: 15px;
		column-gap: 15px;

		.choose-table-type-item {
			padding: 20px 20px;
			border-radius: 10px;
			box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.3);
			cursor: pointer;
			transition: all 200ms ease;

			&:hover {
				background-color: #38588c;
				color: white;
				transform: scale(1.05);
			}
		}

		.active {
			background-color: #587ab1;
			color: white;

			&:hover {
				background-color: #38588c;
				color: white;
			}
		}
	}

	div.buttons-container {
		display: flex;
		justify-content: center;
		gap: 50px;
		margin: 40px 0px;

		button.btn-calculate {
			color: rgb(171, 144, 170);
			padding: 20px 40px;

			&:hover {
				background-color: rgb(167, 178, 167);
				color: white;
			}
		}

		button.btn-send-to-db {
			padding: 20px 40px;
			color: rgb(171, 144, 170);

			&:hover {
				background-color: rgb(167, 178, 167);
				color: white;
			}
		}
	}
}

.graph-device-name {
	max-width: 900px;
	margin: 0 auto;
	font-size: 20px;
	padding-top: 35px;
	font-weight: 600;
}

.graph-type {
	max-width: 850px;
	margin: 0 auto;
	font-size: 18px;
	padding-top: 40px;

	.choose-table-type {
		display: flex;
		flex-wrap: wrap;
		column-gap: 20px;
		row-gap: 30px;

		.choose-table-type-item {
			padding: 20px 20px;
			border-radius: 10px;
			box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.3);
			cursor: pointer;
			transition: all 200ms ease;

			&:hover {
				background-color: #38588c;
				color: white;
				transform: scale(1.05);
			}
		}

		.active {
			background-color: #587ab1;
			color: white;

			&:hover {
				background-color: #38588c;
				color: white;
			}
		}
	}

	.toggle-ref-line {
		margin-top: 40px;
		display: inline-flex;
		padding: 20px 20px;
		border-radius: 10px;
		box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.3);
		cursor: pointer;
		transition: all 200ms ease;

		&:hover {
			background-color: #38588c;
			color: white;
			transform: scale(1.05);
		}
	}
}

.xl-table-container {
	max-width: 1050px;
	margin: 0 auto;
	margin-top: 30px;
	display: grid;
	grid-template-columns: 70px 900px;
	grid-template-rows: auto;

	.xl-table {
		grid-column: 2/3;
		
		box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.3);
		border-right: none;
		border-bottom: none;
		word-break: break-all;

		div.xl-table-header {
			font-weight: 700;
			display: flex;
			background-color: rgb(171, 144, 170);
			color: white;
			top: 0;
			z-index: 999;

			div {
				padding: 0px 20px;
				border-right: 2px solid rgb(167, 178, 167);
				min-height: 60px;
				text-align: center;
				flex: 0 1 250px;
				display: flex;
				justify-content: center;
				align-items: center;

				&:hover {
					cursor: n-resize;
					background-color: #3498db;
				}
			}

			div:nth-child(6n) {
				border: none;
			}
		}

		div.xl-table-row {
			display: flex;

			&:hover {
				cursor: pointer;
				background-color: #e1e4ef;
			}

			div {
				position: relative;
				padding: 0px 20px;
				border-right: 2px solid rgb(167, 178, 167);
				min-height: 60px;
				text-align: center;
				flex: 0 1 250px;
				display: flex;
				justify-content: center;
				align-items: center;

				&:hover {
					color: white;
					background-color: #3498db;
				}

				div.editable-cell {
					position: absolute;
					z-index: 9999;
					cursor: default;
					width: 300px;
					background-color: white;
					border: 2px solid black;
					box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.5);

					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-template-rows: 0.7fr 1fr 1fr;
					column-gap: 20px;

					animation-name: appeare;
					animation-duration: 200ms;

					span {
						display: block;
						grid-column: 1/3;
						padding: 20px 0px;
						color: black;
						font-weight: bold;
					}

					textarea#editable-cell {
						grid-column: 1/3;
						height: 50px;
						padding: 0px 20px;
						padding-top: 13px;
						margin-bottom: 20px;
						resize: none;
						border: 2px solid black;
						outline: none;

						&:hover {
							border-color: #7864ED;
						}

						&:focus {
							border-color: #751692;
						}
					}

					button.btn-close {
						background-color: #38588c;

						&:hover {
							background-color: #587ab1;
						}

						&:active {
							background-color: white;
							color: black;
						}
					}

					button.btn-submit {
						background-color: #3498db;

						&:hover {
							background-color: #5fa7d6;
						}

						&:active {
							background-color: white;
							color: black;
						}
					}
				}
			}

			div:nth-child(6n) {
				border: none;
			}
		}

		div.xl-table-grey-bg {
			background-color: #e1e4ef;
		}
	}


	.collapse-button {
		grid-column: 1/2;
		// justify-self: center;

		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #f2f2f2;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		top: 5px;
		// left: -10%;
		color: rgb(75, 72, 72);
		cursor: pointer;
		font-size: 40px;
		z-index: 1000;
		-webkit-transition: all 200ms ease-in-out;
		transition: all 200ms ease-in-out;

		&:hover {
			background-color: #e0e0e0;
			transform: scale(1.2);
		}

		&:active {
			background-color: #c5c5c5;
		}
	}

}


span.table-name {
	font-size: 1.5em;
	font-weight: 700;
	display: flex;
	justify-content: center;

	// animation-name: appeare;
	// animation-duration: 500ms;
}

.table-bass {
	// animation-name: appeare;
	// animation-duration: 500ms;
	max-width: 900px;
	margin: 30px auto;
	box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.3);
	display: grid;
	grid-template-columns: repeat(5, minmax(100px, 1fr));
	grid-template-rows: 50px 50px 50px;

	div {
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: 2px solid rgb(167, 178, 167);
	}

	div:nth-child(5n) {
		border: none;
	}

	div:nth-child(12) {
		&:hover {
			cursor: pointer;
			background-color: #eabdbd;
		}
	}

	div:nth-child(13) {
		&:hover {
			cursor: pointer;
			background-color: rgb(189, 209, 234);
		}
	}

	div:nth-child(14) {
		&:hover {
			cursor: pointer;
			background-color: rgb(234, 223, 189);
		}
	}

	div.table-bass-header-item {
		color: white;
		background-color: rgb(171, 144, 170);
	}
}


#error-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;

	h1 {
		margin-bottom: 50px;
	}

	p {
		margin-bottom: 50px;
	}
}



.panel-form {
	max-width: 1200px;
	margin: 20px auto;
	padding: 50px 50px;
	background-color: rgba(0, 0, 0, 0);
	box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.3);
	border: 3px solid black;
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;

	h1 {
		padding-bottom: 50px;
		text-align: center;
		text-decoration: underline;
	}

	#add-feedback-form {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;

		div {
			width: 80%;

			input {
				width: 100%;
				outline: none;
				height: 50px;
				margin: 0 auto;
				padding-left: 30px;
				border: 2px solid #9E86FF;

				&:hover {
					border: 2px solid #751692;
				}

				&:focus {
					border: 2px solid black;
				}
			}

			label {
				display: inline-block;
				padding-bottom: 10px;
			}
		}
		
		.checkbox-container {
			padding-bottom: 10px;
			position: relative;
			user-select: none;

			input[type=checkbox] {
				height: 0;
				width: 0;
			}

			 input[type=checkbox]:checked ~ .checkmark {
				background-color: #9E86FF;

				&:after {
					display: block;
				}
			  }

			.checkmark {
				position: absolute;
				top: -5px;
				left: 180px;
				height: 30px;
				width: 30px;
				border: 2px solid #9E86FF;
				transition: all 150ms ease-in-out;

				&:after {
					position: absolute;
					display: none;
					content:'✓';
					font-size: 25px;
					font-weight: bold;
					top: -5px;
					width: 5px;
					height: 20px;
					left: 4px;
					color: white;
				}
			}
		}

		.table-corner-frequencies-container {
			padding-bottom: 20px;
			
			span {
				display: block;
				padding-bottom: 20px;
			}

			.table-corner-frequencies {
				width: 100%;
				display: grid;
				grid-template-columns: repeat(4, minmax(50px, 1fr));
				grid-template-rows: 60px 60px 60px;
				padding-bottom: 20px;
				font-weight: bold;
				border: 2px solid black;
	
				div {
					display: flex;
					align-items: center;
					justify-content: center;
				}
	
				label {
					padding-bottom: 0px;
				}
			}
		}

		.choose-type {
			select {
				appearance: none;
				background-color: transparent;
				border: none;
				margin: 0;
				width: 100%;
				cursor: inherit;
				line-height: inherit;
				outline: none;
				height: 50px;


				&:focus+.focus {
					position: absolute;
					top: -2px;
					left: -2px;
					right: -2px;
					bottom: -2px;
					border: 2px solid black;
					border-radius: inherit;
					color: black;
				}
			}

			.select {
				width: 100%;
				border: 2px solid #9E86FF;
				border-radius: 5px;
				padding: 0px 30px;
				cursor: pointer;
				background-color: #fff;
				background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
				display: grid;
				grid-template-areas: "select";
				align-items: center;
				position: relative;

				&:hover {
					border-color: #751692;
				}

				&:after {
					justify-self: end;
					content: "";
					width: 0.8em;
					height: 0.5em;
					background-color: black;
					clip-path: polygon(100% 0%, 0 0%, 50% 100%);
				}
			}

			select,
			.select:after {
				grid-area: select;
			}
		}

		.volume-tabs-wrapper {

			.volume-tabs-container {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 15px;
				margin-bottom: 20px;
			
				.volume-tab {
					width: 100%;
					display: grid;
					grid-template-columns: 160px 30px 30px 1fr 50px;
					grid-template-rows: 1fr;
					gap: 20px;
					align-items: end;
					justify-items: end;
		
					.delete-tab-item {
						justify-self: center;
					}
			
					.file-name {
						padding-left: 20px;
						padding-bottom: 5px;
						width: 100%;

						.file-name-inner-container {
							display: grid;
							grid-template-columns: 30px 1fr;
							grid-template-rows: 1fr;
							align-items: center;

							svg {
								color: green;
							}
						}
					}

					.validation-message {
						padding-bottom: 5px;
						justify-self: center;
					}
			
					.volume-tab-input {
						width: 100%;
					}
		
					.icons-label {
						-webkit-transition: all 200ms ease-in-out;
						transition: all 150ms ease-in-out;
						padding-bottom: 5px;

						&:hover{
							cursor: pointer;
							transform: scale(1.1);
						}
					}
		
					.upload-csv-lable {
						&:hover{
							color: rgb(144, 238, 147);
						}
					}
		
					.upload-txt-lable {
						&:hover{
							color: rgb(171, 175, 255);
						}
					}
		
					.delete-tab-lable {
						&:hover{
							color: rgb(253, 69, 69);
						}
					}

					.upload-csv-file {
						display: none;
					}
			
					.upload-txt-file {
						display: none;
					}
				}
			}

			.add-new-volume-tab {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				.add-icon {
					width: 50px;
					height: 50px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					-webkit-transition: all 200ms ease-in-out;
					transition: all 200ms ease-in-out;
	
	
					&:hover {
						cursor: pointer;
						color: white;
						background-color: rgb(171, 175, 255);
					}
				}
			}
		}

		div.upload-container-btns {
			width: 100%;
			display: flex;
			justify-content: space-evenly;

			button {
				width: 200px;

				&:disabled {
					background-color: dimgrey;
				}
			}
		}
	}
}

.calculations-container {
	max-width: 1450px;
	margin: 30px auto;

	display: grid;
	grid-template-columns: 380px 1fr;
	grid-template-rows: auto;
	gap: 20px;

	.calculations-container-aside {
		padding-left: 20px;
		align-self: start;
		position: sticky;
		top: 50px;

		.calculations-companies {
			// border: 2px solid black;
			border-radius: 10px;
			// box-shadow: 5px 10px 15px 0px rgba(0,0,0,0.1);
			// box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
			box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.3);
			padding: 20px 20px;
			display: flex;
			flex-wrap: wrap;
			row-gap: 10px;
			
			.calculations-companies-item {
				padding: 10px 10px;
				margin-right: 10px;
				background-color: #e1e4ef;
				color:#751692;
				border-radius: 10px;
				cursor: pointer;
	
				&:hover {
					background-color: black;
					color: white;
				}
			}
	
			.active {
				background-color: black;
				color: white;
			}
		}
	
		.calculations-categories {
			// grid-column: 1/2;
			height: 130px;
			margin-top: 30px;
			// border: 2px solid black;
			border-radius: 10px;
			// box-shadow: 5px 10px 15px 0px rgba(0,0,0,0.1);
			// box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
			box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.3);
			padding: 20px 20px;
			display: flex;
			flex-wrap: wrap;
			row-gap: 10px;
			
			.calculations-categories-item {
				padding: 10px 10px;
				margin-right: 10px;
				background-color: #e1e4ef;
				color:#751692;
				border-radius: 10px;
				cursor: pointer;
	
				&:hover {
					background-color: black;
					color: white;
				}
			}
	
			.active {
				background-color: black;
				color: white;
			}
		}

		.calculations-devices-names {
			padding-top: 50px;
			display: flex;
			align-items: flex-start;
			flex-direction: column;	
			row-gap: 20px;
			
			.device-container {

				box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.3);
				border-radius: 10px;
				transition: all 200ms ease;
				cursor: pointer;
				padding: 15px 15px;

				&:hover {
					box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.5);
				}
				
				.name-item {
					word-break: break-all;

					span.tooltip {
						background-color: rgba(0,0,0, 0.8);
						border-radius: 15px 15px 15px 0px;
						box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
						color: #fff;
						margin-left: -200px;
						margin-top: -80px;
						opacity: 0; 
						padding: 10px 30px 10px 30px;
						position: absolute;
						text-decoration: none;
						visibility: hidden; 
						width: 300px;
						z-index: 999;
						text-wrap: pretty;
						transition: all 200ms ease;
					}	
					
					&:hover span {
						opacity: 1;
						visibility: visible;
					}
				}

				.opened {
					font-weight: bold;
					padding-bottom: 10px;
				}

				.configs-list {
					padding-left: 30px;
					display: flex;
					flex-direction: column;
					gap: 20px;

					.config-container {

						.config-header {
							text-decoration: underline;
							color: #2274ab;
	
							&:hover {
								color:rgb(219, 108, 86);
								cursor: pointer;
							}	
						}
	
						.opened {
							color:rgb(152, 40, 17);
						}
					}

					.volume-container {
						display: flex;
						align-items: flex-start;
						flex-direction: column;	
						padding-left: 30px;
		
						.inner-wrapper {
							display: flex;
							flex-direction: row;
							padding-top: 10px;
		
							.volume-item {
								text-align: center;
								padding: 7px 20px;
								border-radius: 10px;
								box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.1);
								background-color: white;
								transition: all 200ms ease;
			
								&:hover {
									background-color: #38588c;
									color: white;
									cursor: pointer;
								}
							}
			
							.list-line {
								align-self: center;
								width: 30px;
							}
	
							.active {
								background-color: #587ab1;
								color: white;
	
								&:hover {
									background-color: #38588c;
									color: white;
								}
							}
						}
					}
				}
			}
	
			.opened {
				background-color: #f4f4f4;
			}
		}
	}

	.calculations-container-main {
	
		.calculations-table {
			grid-column: 2/3;
			// height: 1000px;
			// border: 2px dashed orange;
		}
	}
}

.compare-container {
	max-width: 1450px;
	margin: 30px auto;

	display: grid;
	grid-template-columns: 400px 1fr;
	grid-template-rows: auto;
	gap: 20px;

	.compare-container-aside {
		padding-left: 20px;
		align-self: start;
		position: sticky;
		top: 50px;

		.compare-ratings {
			margin-bottom: 30px;
			// border: 2px solid black;
			border-radius: 10px;
			// box-shadow: 5px 10px 15px 0px rgba(0,0,0,0.1);
			box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.3);
			padding: 20px 20px;
			display: flex;
			flex-wrap: wrap;
			row-gap: 10px;
			
			.compare-ratings-item {
				padding: 10px 10px;
				margin-right: 10px;
				background-color: #e1e4ef;
				color:#751692;
				border-radius: 10px;
				cursor: pointer;
	
				&:hover {
					background-color: black;
					color: white;
				}
			}
	
			.active {
				background-color: black;
				color: white;
			}
		}

		.compare-companies {
			margin-bottom: 30px;
			// border: 2px solid black;
			border-radius: 10px;
			// box-shadow: 5px 10px 15px 0px rgba(0,0,0,0.1);
			box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.3);
			padding: 20px 20px;
			display: flex;
			flex-wrap: wrap;
			row-gap: 10px;
			
			.compare-companies-item {
				padding: 10px 10px;
				margin-right: 10px;
				background-color: #e1e4ef;
				color:#751692;
				border-radius: 10px;
				cursor: pointer;
	
				&:hover {
					background-color: black;
					color: white;
				}
			}
	
			.active {
				background-color: black;
				color: white;
			}
		}
	
		.compare-categories {
			margin-bottom: 30px;
			height: 130px;

			// border: 2px solid black;
			border-radius: 10px;
			// box-shadow: 5px 10px 15px 0px rgba(0,0,0,0.1);
			box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.3);
			padding: 20px 20px;
			display: flex;
			flex-wrap: wrap;
			row-gap: 10px;
			
			.compare-categories-item {
				padding: 10px 10px;
				margin-right: 10px;
				background-color: #e1e4ef;
				color:#751692;
				border-radius: 10px;
				cursor: pointer;
	
				&:hover {
					background-color: black;
					color: white;
				}
			}
	
			.active {
				background-color: black;
				color: white;
			}
		}
	}

	.compare-graphs-container {
	
		.graph-ratings {
			grid-column: 2/3;
			// height: 1000px;
			// border: 2px dashed orange;
		}
	}
}

.info-container {
	display: flex;
	flex-direction: column;
	max-width: 1300px;
	margin: 0 auto;
	justify-content: center;
	gap: 50px;
	padding: 50px 100px;

	.info-item {
		display: grid;
		grid-template-columns: 0.35fr 0.01fr 1fr;
		justify-items: center;
		align-items: center;

		border-radius: 5px;
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.1);
		padding: 40px 20px;
		-o-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-webkit-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;

		.info-item-header {
			font-size: x-large;
			color: #751692;
			text-align: center;
			padding: 40px 0px;
			display: grid;
			align-items: center;

			&:hover {
				cursor:default;
			}
		}

		.info-item-desc {
			
			font-size: large;
			word-break: break-word;
			padding: 40px 20px;
			padding-left: 30px;
		}

		.info-item-separator {
			border-right: 2px solid #751692;
			width: 100%;
			height: 100%;
		}

		.product-item-link {
			display: flex;
			justify-content: space-between;
			// text-align: right;
			padding: 0px 40px;
		}
		
		&:hover {
			// transform: scale(1.02);
			box-shadow: 0 10px 30px 2px rgba(0,0,0,0.2);
		}
	}
}


.graph-ratings {
	padding-top: 20px;
	header {
		display: flex;
		justify-content: center;
		font-size: 1.5em;
		font-weight: 600;
	}
}

.all-devices-container {
	max-width: 1450px;
	margin: 30px auto;

	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	gap: 20px;
	justify-items: center;

	.all-devices-ratings {
		max-width: 900px;
		margin-bottom: 30px;
		// border: 2px solid black;
		border-radius: 10px;
		// box-shadow: 5px 10px 15px 0px rgba(0,0,0,0.1);
		box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.3);
		padding: 20px 20px;
		display: flex;
		flex-wrap: wrap;
		row-gap: 10px;
		
		.all-devices-ratings-item {
			padding: 10px 10px;
			margin-right: 10px;
			background-color: #e1e4ef;
			color:#751692;
			border-radius: 10px;
			cursor: pointer;

			&:hover {
				background-color: black;
				color: white;
			}
		}

		.active {
			background-color: black;
			color: white;
		}
	}

	.all-devices-companies {
		max-width: 900px;
		margin-bottom: 30px;
		// border: 2px solid black;
		border-radius: 10px;
		// box-shadow: 5px 10px 15px 0px rgba(0,0,0,0.1);
		box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.3);
		padding: 20px 20px;
		display: flex;
		flex-wrap: wrap;
		row-gap: 10px;
		
		.all-devices-companies-item {
			padding: 10px 10px;
			margin-right: 10px;
			background-color: #e1e4ef;
			color:#751692;
			border-radius: 10px;
			cursor: pointer;

			&:hover {
				background-color: black;
				color: white;
			}
		}

		.active {
			background-color: black;
			color: white;
		}
	}

	.all-devices-graph-container {
		justify-self: start;

		.all-devices-graph {
			width: 100%;

			header {
				font-size: 1.5em;
				font-weight: 600;
				display: flex;
				justify-content: center;
				padding-top: 50px;
				padding-bottom: 50px;
			}
		}
	}
}

.compare-graph-multiple {
	width: 1000px;
	margin: 0 auto;
}

.filter-box {
	max-width: 1100px;
	margin: 0 auto;
	margin-bottom: 30px;
	border-radius: 10px;
	box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.3);
	padding: 20px 20px;
	display: flex;
	flex-wrap: wrap;
	row-gap: 10px;

	.config-tab-item {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-right: 20px;
		margin-bottom: 5px;
		border-radius: 10px;
		box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.3);
		padding: 10px 5px;

		.config-tab-item-title {
			// padding-bottom: 10px;
			padding-right: 10px;
			padding-left: 10px;
			font-weight: bolder;

			.config-version {
				text-decoration: underline;
				color: #8acdff;
			}
		}

		.config-tab-item-volumes {
			display: flex;
			flex-wrap: wrap;
		}
	}
	
	.filter-box-item {
		padding: 10px 10px;
		margin-right: 10px;
		background-color: #e1e4ef;
		color:#751692;
		border-radius: 10px;
		cursor: pointer;

		&:hover {
			background-color: black;
			color: white;
		}
	}

	.active {
		background-color: black;
		color: white;
	}
}

.graph-lines-names {
	max-width: 1100px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	column-gap: 10px;
	align-items: center;

	.graph-lines-names-inner-container {
		position: relative;

		.devices-graph-lines-names-item {
			display: flex;
			align-items: center;
	
			svg {
				width: 40px;
				height: 30px;
			}
		}

		.delete-graph-lines-names-icon {
			position: absolute;
			left: 50%;
			top: 0;

			&:hover {
				cursor: pointer;
				color: rgb(217, 0, 255);
			}
		}
	}
}

.arrow-down {
	border: solid white;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

.devices-container {
	max-width: 1400px;
	margin: 30px auto;

	display: grid;
	grid-template-columns: 0.43fr 1fr;
	grid-template-rows: auto;

	.filter-box-container {
		padding-left: 20px;
		align-self: start;
		position: sticky;
		top: 50px;

		.filter-box-aside {
			margin-bottom: 30px;
			// border: 2px solid black;
			border-radius: 10px;
			// box-shadow: 5px 10px 15px 0px rgba(0,0,0,0.1);
			box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.2);
			padding: 20px 20px;
			display: flex;
			flex-wrap: wrap;
			row-gap: 10px;
			
			.filter-box-aside-item {
				padding: 10px 10px;
				margin-right: 10px;
				background-color: #e1e4ef;
				color:#751692;
				border-radius: 10px;
				cursor: pointer;
	
				&:hover {
					background-color: black;
					color: white;
				}
			}
	
			.active {
				background-color: black;
				color: white;
			}
		}
	}

	.devices-list-container {
		display: grid;
		grid-template-rows: 1fr;
		padding: 0px 100px;
		gap: 30px;

		// .devices-table {
		// 	.devices-list-item {
		// 		border: 2px solid black;
		// 		border-radius: 8px;
		// 		box-shadow: 5px 10px 15px 0px rgba(0,0,0,0.1);
		// 		padding: 40px 50px;
		// 		// margin-bottom: 30px;
		// 		display: flex;
		// 		flex-direction: column;
		// 		justify-content: flex-start;
		// 	}

		// 	.devices-list-item-main {
		// 		// width: 70%;
		// 		display: grid;
		// 		grid-template-columns: 300px 50px 50px 100px 50px 50px;
		// 		gap: 10px;
		// 		word-wrap: break-word;
		
		// 		.devices-list-item-main-company {
		// 			padding-bottom: 10px;
		// 		}
		
		// 		.devices-list-item-main-name {
		// 			font-size: larger;
		// 			font-weight: 700;
		// 			padding-bottom: 10px;
				
		// 			a {
		// 				&:hover {
		// 					cursor: pointer;
		// 					color: #813199;
		// 				}
		// 			}

					
		// 		}
		
		// 		.devices-list-item-main-category {
		// 			display: inline-block;
		// 			background-color: #e1e4ef;
		// 			color: #3498db;
		// 			padding: 5px 15px;
		// 			border-radius: 10px;
		// 		}

		// 		.devices-list-item-main-volume {
		// 			padding-bottom: 10px;
		// 		}
		// 	}







		// }

		.devices-list-title {
			height: 80px;
			color: white;
			background-color: #2c2541;
			padding: 15px 35px;
			border: 2px solid black;
			border-radius: 10px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 15px;

			.devices-list-title-number {
				margin-left: 20px;
			}

			.devices-list-title-add-btn {
				margin-left: auto;
				color: white;
				background-color: #751692;
				padding: 10px 30px;
				border-radius: 10px;
				text-decoration: none;
				border: none;
				font-weight: 700;
				transition: all 200ms ease;
			
				&:active {
					background-color: white;
					color: black;
				}

				&:hover {
					transform: translateY(-2px);
					background-color: #813199;
				}
			}

			.devices-list-title-sortby {
				margin-left: 50px;
				// font-size: smaller;
				// color: #3498db;

				.sortby-list-arrow {
					cursor: pointer;
					color: #3498db;
					position: relative;

					ul.sortby-list {
						position: absolute;
						width: 150px;
						list-style: none;
						color: gray;
						background-color: whitesmoke;
						text-decoration: none;
						padding: 10px 0px;
						left: -20px;
						top: 25px;

						li {
							padding: 10px 20px;

							&:hover {
								color: white;
								background-color: #3498db;
							}
						}
					}
				}
				
			}
		}

		.devices-list-item-container {
			margin-bottom: 30px;
			border-radius: 8px;
			box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);

			.devices-list-item {
				padding: 20px 50px;
				cursor: pointer;
			
				.devices-list-item-main {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					
					.devices-list-item-main-name {
						font-size: larger;
						font-weight: 700;
					}
			
					.devices-list-item-main-category {
						background-color: #e1e4ef;
						color: #3498db;
						padding: 5px 15px;
						border-radius: 10px;
					}
				}
			
			}

			.add-new-volume-for-device {
				width: 100%;
				padding-bottom: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 70px;

				div {
					height: 40px;
					padding-left: 10px;
					padding-right: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					-webkit-transition: all 200ms ease-in-out;
					transition: all 200ms ease-in-out;


					border-radius: 10px;
					text-decoration: none;
					font-weight: 700;
	
					cursor: pointer;

				}

				.add-icon {
					&:hover {
						color: white;
						background-color: #813199;
					}
				}

				.edit-icon {
					&:hover {
						color: white;
						background-color: rgb(171, 175, 255);
					}
				}
			}

			.prompt {
				position: absolute;
				color: black;
				padding: 30px 30px;
				top: -60px;
				left: -80px;
				background-color: white;
				border: 2px solid black;
				display: grid;
				grid-template-rows: 50px 40px;
				grid-template-columns: 120px 120px;
				gap: 10px;

				span {
					grid-column: 1/3;
					align-self: center;
				}

				.prompt-yes-btn {
					color: rgb(201, 77, 77);
					transition: none;

					&:hover {
						border: 2px solid red;
					}
				}

				.prompt-cancel-btn {
					color: gray;
					transition: none;
					
					&:hover {
						border: 2px solid gray;
					}
				}
			}

			&:hover {
				box-shadow: 0 10px 30px 0px rgba(0,0,0,0.2);
			}
		}
	}
}

.compare-header-container {
	max-width: 900px;
	margin: 0 auto;
	height: 50px;
	font-size: x-large;
	font-weight: 700;
	margin-bottom: 30px;

	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;

	div {
		cursor: pointer;

		&:hover {
			color:#2274ab;
		}
	}

	.header-moving-rect {
		position: absolute;
		z-index: -1;
		height: 100%;
		width: 160px;
		background-color: #e1e4ef;
		border-radius: 10px;
		transition: 0.5s;
	}

	.header-item-active {
		color:#751692;
	}
}

.compare-header {
	display: flex;
	justify-content: center;
	padding-top: 20px;
	padding-bottom: 20px;
}

.something-went-wrong {
	display: flex;
	justify-content: center;
	padding-top: 100px;
	font-size: 1.2em;
	font-weight: 600;
}

// Slope rate table for comparing devices

.slope-table-container {
	max-width: 1100px;
	margin: 0 auto;
	margin-top: 60px;
	margin-bottom: 60px;
	// border: 2px solid black;

	.slope-table-header {
		font-size: larger;
		font-weight: 700;
		display: flex;
		justify-content: end;
		// border-bottom: 2px solid black;

		div {
			padding: 0px 20px;
			// border-left: 2px solid black;
			min-height: 45px;
			text-align: center;
			flex: 0 1 250px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

	}

	.slope-table-row-container {

		.slope-table-row {
			display: grid;
			grid-template-columns: 350px 1fr;
			grid-template-rows: 1fr;
			word-break: break-all;
			border-left: 2px solid black;
	
			.slope-table-row-name {
				display: grid;
				grid-template-columns: 180px 1fr;
				grid-template-rows: 1fr 1fr;

				border-right: 2px solid black;
	
				.device-name-field {
					padding-left: 20px;
					padding-right: 20px;
					grid-row: 1/3;
					justify-content: center;
				}

				.frequency-field {
					// border-bottom: 2px solid black;
				}
				
			}
	
			.slope-table-row-data {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				grid-template-rows: 1fr 1fr;
	
				div {	
					// border-bottom: 2px solid black;
					border-right: 2px solid black;

					&:hover {
						cursor: pointer;
						color: #751692;
						background-color: #c4ddef;
					}
				}
			}
	
			div {
				min-height: 45px;
				text-align: center;
				flex: 0 1 250px;
				display: flex;
				justify-content: center;
				align-items: center;

			}
	
		}		

		.slope-table-row:first-child {
			border-top: 2px solid black;
		}
	}

	.slope-table-row-container:nth-child(odd) {
		background-color: #e1e4ef;
	}

	.slope-table-row-container:last-child {
		border-bottom: 2px solid black;
	}
}

.context-menu {
	position: absolute;
	width: 200px;
	background-color: white;
	border: 2px solid black;
	box-sizing: border-box;
	font-weight: 600;
	font-size: large;
	text-align: center;
	z-index: 999;

	ul {
		box-sizing: border-box;
		padding: 10px;
		margin: 0;
		list-style: none;
		
		li {
			padding: 15px 12px;

			&:hover {
				cursor: pointer;
				background-color: #a8daff;
				color:white;
			}
		}
	}
}

.color-picker {
	position: absolute;
	z-index: 999;
	background-color: white;
	border: 2px solid black;
	width: 320px;
	padding-bottom: 20px;
	padding-top: 20px;
	word-break: break-all;
	animation-name: slide;
	animation-duration: 200ms;

	.device-name-container {
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 20px;
		font-weight: 600;
		font-size: large;
		border-bottom: 2px solid black;
		text-align: center;
	}

	.inputs-container {
		padding-top: 35px;
		padding-bottom: 35px;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		font-weight: 600;
		font-size: large;

		input {
			width: 50px;
			height: 30px;
			cursor: pointer;
			padding-left: 3px;
			padding-right: 3px;
			border: 2px solid black;
		}
	}

	.buttons-container {
		// padding-top: 20px;
		display: flex;
		justify-content: space-around;

		button {
			width: 100px;
			height: 40px;
			border: 2px solid black;
			font-weight: 700;
			transition: all 200ms ease;
			background-color: whitesmoke;
		
			&:hover {
				transform: scale(1.05);
				background-color: #a8daff;
			}
		}
	}
}

.amplifier-configs-list {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 20px;
	// margin-bottom: 20px;
	padding-bottom: 20px;

	.config-button {
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;

		border-radius: 10px;
		text-decoration: none;
		border: none;
		font-weight: 700;
		transition: all 200ms ease;
	}

	.config-title {
		display: grid;
		grid-template-columns: 1fr 0.5fr 0.5fr;
		justify-items: center;

		padding: 20px 20px;

		.config-version {
			padding: 10px 10px;
			justify-self: start;
			font-size: 17px;
			font-weight: 700;
			text-decoration: underline;
			color:rgb(152, 40, 17);

			&:hover {
				color:rgb(219, 108, 86);
				cursor: pointer;
			}
		}

		.add-new-volume {
			&:hover {
				color: white;
				background-color: #109cc3;
				cursor: pointer;
			}
		}

		
		.edit-config {
			&:hover {
				color: white;
				background-color: #dbb274;
				cursor: pointer;
			}
		}
	}

	.amplifier-config-container {
		background-color: #f1f3faac;
		border-radius: 10px;
		margin-left: 20px;
		margin-right: 20px;

		.volume-list-item {
			width: 90%;
			margin: auto;
			display: grid;
			align-items: center;
			grid-template-rows: 60px;
			grid-template-columns: 0.3fr 1.5fr 0.5fr 0.5fr;
			gap: 10px;
	
			.device-name {
				transition: all 200ms ease;
	
				span {
					font-weight: 600;
					&:hover {
						// font-weight: 600;
						cursor: pointer;
					}
				}
	
			}
	
			.edit {	
				&:hover {
					color: #e1e4ef;
					background-color: #3498db;
					cursor: pointer;
				}
			}
	
			.delete {
				&:hover {
					color: rgb(201, 77, 77);
					background-color: rgb(240, 240, 240);
					cursor: pointer;
				}
			}
		}
	}
}















.oauth-button {
	display: block;
	width: 150px;
	margin: 0 auto;
	padding-top: 30px;
	cursor: pointer;
	transition: all 200ms ease;

	&:hover {
		transform: scale(1.05);
	}
}

.loading-container {
	width: auto;
	height: 50vh;
	display: flex;
	justify-content: center;
	align-items: center;

	$side-size: 200px;
	$size-diff: 20px;

	.circle {
		border-radius: 50%;
		animation: spin-purple 1s alternate infinite;
		position: absolute;
	}

	.circle:nth-child(9) {
		animation-delay: 0.9s;
		background-color: white;
	}

	.purple-1 {
		animation-delay: 0.1s;
		width: $side-size;
		height: $side-size;
		background-color: #4C208C;
	}

	.purple-2 {
		animation-delay: 0.2s;
		width: calc($side-size - $size-diff * 1);
		height: calc($side-size - $size-diff * 1);
		background-color: #632BB8;
	}

	.purple-3 {
		animation-delay: 0.3s;
		width: calc($side-size - $size-diff * 2);
		height: calc($side-size - $size-diff * 2);
		background-color: #7E37E4;
	}

	.purple-4 {
		animation-delay: 0.4s;
		width: calc($side-size - $size-diff * 3);
		height: calc($side-size - $size-diff * 3);
		background-color: #945CE8;
	}

	.purple-5 {
		animation-delay: 0.5s;
		width: calc($side-size - $size-diff * 4);
		height: calc($side-size - $size-diff * 4);
		background-color: #AE85EC;
	}

	.purple-6 {
		animation-delay: 0.6s;
		width: calc($side-size - $size-diff * 5);
		height: calc($side-size - $size-diff * 5);
		background-color: #C9AFF2;
	}

	.purple-7 {
		animation-delay: 0.7s;
		width: calc($side-size - $size-diff * 6);
		height: calc($side-size - $size-diff * 6);
		background-color: #E4D8F9;
	}

	.purple-8 {
		animation-delay: 0.8s;
		width: calc($side-size - $size-diff * 7);
		height: calc($side-size - $size-diff * 7);
		background-color: white;
	}

	.purple-9 {
		animation-delay: 0.9s;
		background-color: white;
		width: calc($side-size - $size-diff * 8);
		height: calc($side-size - $size-diff * 8);
	}
}

.loading {
	margin: 0 auto;
	margin-top: 50px;
	border: 10px solid #f3f3f3;
	border-top: 10px solid #3498db;
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}

@keyframes spin-purple {
	0% {
		border-radius: 50%;
		transform: rotate(0);
	}

	20% {
		border-radius: 50%;
		transform: rotate(0);
	}

	90% {
		border-radius: 5%;
		transform: rotate(90deg);
	}

	100% {
		border-radius: 5%;
		transform: rotate(90deg);
	}
}

@-webkit-keyframes appeare {
	from {
		top: 100px;
		opacity: 0
	}

	to {
		top: -60px;
		opacity: 1
	}
}

@keyframes appeare {
	from {
		top: 100px;
		opacity: 0
	}

	to {
		top: -60px;
		opacity: 1
	}
}

@keyframes slide {
	0% {
		opacity: 0;
		transform: translateY(70%);
	}

	100% {
		opacity: 1;
		transform: translateY(0%);
	}
}

@-webkit-keyframes slide {
	0% {
		opacity: 0;
		-webkit-transform: translateY(70%);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0%);
	}
}

@-webkit-keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes animatetop {
	from {
		top: -300px;
		opacity: 0
	}

	to {
		top: 0;
		opacity: 1
	}
}

@keyframes animatetop {
	from {
		top: -300px;
		opacity: 0
	}

	to {
		top: 0;
		opacity: 1
	}
}

// @keyframes identifier {
// 	from { h }
// }

//  @-webkit-keyframes appeare {
// 	from {top: 100px; opacity: 0}
// 	to {top: -30px; opacity: 1}
// }

// @keyframes appeare {
// 	from {top: 100px; opacity: 0}
// 	to {top: -30px; opacity: 1}
//  }

@-webkit-keyframes fromleft {
	from {
		left: -1200px;
		opacity: 0
	}

	to {
		left: 0px;
		opacity: 1
	}
}

@keyframes fromleft {
	from {
		left: -1200px;
		opacity: 0
	}

	to {
		left: 0px;
		opacity: 1
	}
}

@-webkit-keyframes fromright {
	from {
		right: -1200px;
		opacity: 0
	}

	to {
		right: 0px;
		opacity: 1
	}
}

@keyframes fromright {
	from {
		right: -1200px;
		opacity: 0
	}

	to {
		right: 0px;
		opacity: 1
	}
}

@-webkit-keyframes appear-to-right {
	from {
		left: 0px;
		opacity: 1
	}

	to {
		left: 1000px;
		opacity: 0
	}
}

@keyframes appear-to-right {
	from {
		left: 0px;
		opacity: 1
	}

	to {
		left: 1000px;
		opacity: 0
	}
}

@-webkit-keyframes appear-to-left {
	from {
		right: 0px;
		opacity: 1
	}

	to {
		right: 1000px;
		opacity: 0
	}
}

@keyframes appear-to-left {
	from {
		right: 0px;
		opacity: 1
	}

	to {
		right: 1000px;
		opacity: 0
	}
}
